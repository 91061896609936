























































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";

@Component({
  name: "Login",
  components: {},
})
export default class extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };
  private valid = false;
  private codeSent = false;
  private loading = false;
  private show1 = false;
  private mobile = "";
  private code = "";

  userNameRules = [
    (v) => !!v || "نام کاربری الزامی میباشد",
    (v) =>
      /[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com|outlook.com|hotmail.com|yahoo.com)/.test(
        v
      ) ||
      "ایمیل وارد شده  اشتباه است ایمیلهای مورد تایید gmail,outlook,hotmail,yahoo",
  ];
  private async verifyCode() {
    this.loading = true;
    try {
      await UserModule.VerifyCode({ code: this.code, mobile: this.mobile });
      this.loading = false;
      if (UserModule.verfied) this.$router.push("/change-password");
    } catch (error) {
      this.loading = false;
    }
  }
  private validationForm() {
    this.$refs.form.validate();
  }
}
